import { useSuspenseQuery, useMutation, SuspenseQueryHookOptions } from "@apollo/client";

import { Box, Grid } from "@mui/material";

import { getFragmentData } from "../../__generated__";
import {
	GetProductDocument,
	GetProductsDocument,
	ProductItemFragmentDoc,
	DeleteProductDocument,
	GetProductsQuery,
	Exact,
} from "../../__generated__/graphql";
import { client } from "../../apollo";
import { AppNoResults } from "../../shared/AppNoResults";

import { ProductCard } from "./ProductCard";

// TODO: Pagination
const ProductsList = function ProductsList({
	options,
}: {
	options: SuspenseQueryHookOptions<GetProductsQuery, Exact<{ first: number }>>;
}) {
	const [[deleteProduct], { data }] = [
		useMutation(DeleteProductDocument),
		useSuspenseQuery(GetProductsDocument, options),
	];

	const deleteProductOptimstic = async (id: string) => {
		await deleteProduct({
			variables: { id },
			update(cache) {
				const result = cache.readQuery({
					query: GetProductsDocument,
					variables: options.variables,
				});

				const edges = result?.products.edges
					.map((edge) => ({
						...edge,
						node: getFragmentData(ProductItemFragmentDoc, edge.node),
					}))
					.filter((edge) => edge.node.id !== id);

				if (edges) {
					cache.writeQuery({
						query: GetProductsDocument,
						variables: options.variables,
						data: {
							products: {
								__typename: "ProductConnection" as const,
								edges,
							},
						},
					});
					cache.evict({ id: cache.identify({ id, __typename: "Product" }) });
					cache.evict({ id: "ROOT_QUERY", fieldName: "product", args: { id } });
					cache.gc();
				}
			},
			optimisticResponse: {
				deleteProduct: {
					id,
					__typename: "Product",
				},
			},
		});
	};

	return (
		<Box sx={{ maxWidth: "1200px", width: "100%", margin: "0 auto" }}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }} sx={{ mt: 2 }}>
				{data.products.edges.map(({ node }) => {
					const fragment = getFragmentData(ProductItemFragmentDoc, node);

					// Save results onto cache -
					// this way each product is cached separately -
					// no need to single request on view.
					if (fragment.id) {
						client.writeQuery({
							id: `ROOT_QUERY`,
							query: GetProductDocument,
							variables: {
								id: fragment.id,
							},
							data: {
								product: {
									...fragment,
								},
							},
						});
					}

					return (
						fragment.id && (
							<Grid item key={fragment.id} xs={12} md={12} lg={6}>
								<ProductCard
									productFragment={fragment}
									onDelete={() => fragment.id && deleteProductOptimstic(fragment.id)}
								/>
							</Grid>
						)
					);
				})}
			</Grid>
			{data.products.edges.length === 0 && <AppNoResults />}
		</Box>
	);
};

export default ProductsList;
