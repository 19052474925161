import { useCallback, useState, useEffect } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Publish, Delete, Error } from "@mui/icons-material";
import { Box, Chip, CircularProgress, Grid, IconButton, Paper } from "@mui/material";

import { PictureItemFragment } from "../__generated__/graphql";
import { theme } from "../theme";

interface FilePreview {
	url: string;
	name: string;
}

export function FileDragDrop({
	filesChange,
	uploaded,
	erroredNames,
	removeFile,
	maxFiles = 100,
	accept,
}: {
	filesChange?: (files: File[]) => void;
	uploaded?: PictureItemFragment[];
	erroredNames?: string[];
	removeFile?: (id?: string) => void;
	maxFiles?: number;
	accept?: Accept;
}) {
	const { t } = useTranslation();

	const [files, setFiles] = useState<File[]>([]);
	const [filePreviews, setFilePreviews] = useState<{ url: string; name: string }[]>([]);

	const getBlobFromFile = (file: File): string =>
		window.URL.createObjectURL(new Blob([file], { type: "image/jpeg" }));

	useEffect(() => {
		uploaded &&
			setFilePreviews(
				uploaded
					.filter((fragment) => !fragment.url.includes("blob"))
					.map((fragment) => ({ url: fragment.thumbnail, name: fragment.name }))
			);
	}, [uploaded]);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const toUpload = [];
			const uploadedNames = uploaded?.map((file) => file.name);

			for (const file of acceptedFiles) {
				if (uploadedNames?.includes(file.name)) {
					continue;
				}
				toUpload.push(file);
			}

			const nextFiles = [...files, ...toUpload];

			if (nextFiles.length > maxFiles) {
				return;
			}

			const allFiles = [...new Map(nextFiles.map((item) => [item["name"], item])).values()];
			setFiles(allFiles);
			filesChange && filesChange(toUpload);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			setFilePreviews((filePreviews) =>
				[
					...new Map([...filePreviews, ...allFiles].map((item) => [item["name"], item])).values(),
				].map((file) => ({
					url: file instanceof File ? getBlobFromFile(file) : file.url,
					name: file.name,
				}))
			);
		},
		[files, uploaded]
	);

	const remove = (name: string, id?: string) => {
		setFiles((files) => files.filter((file) => file.name !== name));
		setFilePreviews((files) => files.filter((file) => file.name !== name));
		removeFile && removeFile(id);
	};

	const uploadedNames = uploaded?.map((fragment) => fragment.name) || [];

	const isLoading = (uploadedNames: string[], file: FilePreview) =>
		!uploadedNames.includes(file.name);
	const isError = (errorNames: string[] = [], file: FilePreview) => errorNames.includes(file.name);

	return (
		<Dropzone accept={accept} onDrop={onDrop}>
			{({ getRootProps, getInputProps }) => (
				<Box>
					{filePreviews.length === 0 && (
						<section
							style={{
								border: `2px dashed ${theme.palette.grey[300]}`,
								backgroundColor: theme.palette.grey[100],
								color: theme.palette.grey[500],
								cursor: "pointer",
							}}
							data-testid="file-drag-drop-input"
						>
							<div
								{...getRootProps()}
								style={{
									flexDirection: "column",
									justifyItems: "center",
									alignItems: "center",
									display: "flex",
									padding: "12px",
								}}
							>
								<input {...getInputProps()} />
								<Publish style={{ fontSize: "64px" }} />
								<p>{t("Choose a file or drag it here")}</p>
							</div>
						</section>
					)}
					{filePreviews.length > 0 && (
						<Box>
							<div style={{ display: "flex", gap: "12px" }}>
								<Grid container rowSpacing={2} columnSpacing={2}>
									{filePreviews.map((filePreview, index) => (
										<Grid data-testid="file-item" item key={index} xs={4} md={4} lg={3}>
											<Paper
												elevation={4}
												sx={{
													height: ["100px", "120px", "120px"],
													overflow: "hidden",
													backgroundImage: `url(${filePreview.url})`,
													backgroundSize: "cover",
													margin: "0 auto",
													position: "relative",
												}}
											>
												{(!isLoading(uploadedNames, filePreview) ||
													isError(erroredNames, filePreview)) && (
													<>
														<Box
															style={{
																left: "4px",
																bottom: "4px",
																position: "absolute",
															}}
														>
															{index === 0 ? <Chip label="Thumbnail" color="primary" /> : <></>}
														</Box>

														<Box
															style={{
																right: "4px",
																top: "4px",
																position: "absolute",
															}}
														>
															<IconButton
																sx={{
																	backgroundColor: "primary.main",
																	color: "white",
																	"&:hover": { backgroundColor: "primary.main" },
																	margin: "2px",
																	zIndex: "100",
																}}
																color="warning"
																aria-label="delete"
																size="small"
																data-testid="file-delete"
																onClick={() =>
																	uploaded && remove(filePreview.name, uploaded[index]?.id)
																}
															>
																<Delete fontSize="small" />
															</IconButton>
														</Box>
													</>
												)}
												{isLoading(uploadedNames, filePreview) && (
													<Box
														sx={{
															position: "absolute",
															height: "100%",
															width: "100%",
															zIndex: "10",
															backgroundColor: `${theme.palette.common.black}`,
															opacity: "50%",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<CircularProgress />
													</Box>
												)}
												{isError(erroredNames, filePreview) && (
													<Box
														sx={{
															position: "absolute",
															height: "100%",
															width: "100%",
															zIndex: "10",
															backgroundColor: `${theme.palette.error.main}`,
															opacity: "50%",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Error
															style={{ fontSize: "64px", color: theme.palette.common.black }}
														/>
													</Box>
												)}
											</Paper>
										</Grid>
									))}
									{filePreviews.length < maxFiles && (
										<Grid
											data-testid="file-drag-drop-input-upload-more"
											{...getRootProps()}
											item
											key={"new item"}
											xs={4}
											md={4}
											lg={3}
										>
											<Paper
												elevation={4}
												sx={{
													height: ["100px", "120px", "120px"],
													border: `2px dashed ${theme.palette.grey[300]}`,
													backgroundColor: theme.palette.grey[100],
													color: theme.palette.grey[500],
													display: "grid",
													alignItems: "center",
													justifyItems: "center",
													margin: "0 auto",
													cursor: "pointer",
												}}
											>
												<input {...getInputProps()} />
												<Publish style={{ fontSize: "48px" }} />
											</Paper>
										</Grid>
									)}
								</Grid>
							</div>
						</Box>
					)}
					<div
						style={{
							display: "flex",
							gap: "12px",
							flexDirection: "row",
							flexWrap: "wrap",
							alignContent: "flex-start",
							alignItems: "flex-start",
						}}
					></div>
				</Box>
			)}
		</Dropzone>
	);
}
