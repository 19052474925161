import { RouterProvider } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { router } from "./router";
import { theme } from "./theme";

export function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
		</ThemeProvider>
	);
}
