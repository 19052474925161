import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Lottie from "lottie-react";

import { Add } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import noResultsAnimation from "../assets/no-results.json";

import { AppButton } from "./AppButton";

export function AppNoResults() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const createProduct = () => {
		navigate("/products/new");
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginTop: "32px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: ["column", "column", "row"],
					justifyContent: "center",
					alignItems: "center",
					flex: 1,
				}}
			>
				<Box sx={{ flex: 2 }}>
					<Typography variant="h2">{t("No products")}</Typography>
					<p>{t("There's no products yet, please create a new one.")}</p>
					<AppButton onClick={createProduct} color="grey" variant="outlined" startIcon={<Add />}>
						{t("Create Product")}
					</AppButton>
				</Box>
				<Lottie style={{ flex: 2 }} animationData={noResultsAnimation} loop={true} />
			</Box>
		</Box>
	);
}
