import { createBrowserRouter } from "react-router-dom";

import { Layout } from "./core/layout";
import { Products } from "./features/products";

export const router = createBrowserRouter([
	{
		id: "root",
		path: "/",
		Component: Layout,
		children: [
			{
				index: true,
				Component: Products,
			},
			{
				path: "products",
				Component: Products,
			},
			{
				path: "products/:id",
				Component: Products,
			},
		],
	},
]);
