import { Outlet } from "react-router-dom";

import { AppDrawer } from "./AppDrawer";

export function Layout() {
	return (
		<>
			<AppDrawer />
			<Outlet />
		</>
	);
}
