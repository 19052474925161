import { initReactI18next } from "react-i18next";

import i18next from "i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
	en: {
		translation: {
			"Create Product": "Create Product",
			Save: "Save",
			"Price *": "Price *",
			SKU: "SKU",
			Stock: "Stock",
			Weight: "Weight",
			Name: "Name",
			Description: "Description",
			Status: "Status",
			"Choose a file or drag it here": "Choose a file or drag it here.",
			"Something went wrong...": "Something went wrong...",
			"Please try again later.": "Please try again later",
			Filters: "Filters",
			Dashboard: "Dashboard",
			Orders: "Orders",
			Products: "Products",
			Customers: "Customers",
			"Search products": "Search products",
			"No products": "No products",
			"There's no products yet, please create a new one.":
				"There's no products yet, please create a new one.",
		},
	},
	fr: {
		translation: {
			"Create Product": "Create Product",
			Save: "Save",
			Price: "Price *",
			SKU: "SKU",
			Stock: "Stock",
			Weight: "Weight",
			Name: "Name",
			Description: "Description",
			"Choose a file or drag it here": "Choose a file or drag it here.",
			"Something went wrong...": "Something went wrong...",
		},
	},
};

void i18next
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		fallbackLng: "en",
		debug: true,
	});

export default i18next;
