import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					h1: "h1",
					h2: "h1",
					h3: "h1",
					h4: "h1",
					h5: "h2",
					h6: "h2",
					subtitle1: "h2",
					subtitle2: "h2",
					body1: "span",
					body2: "span",
				},
			},
		},
	},
});

declare module "@mui/material/ButtonGroup" {
	interface ButtonGroupPropsColorOverrides {
		grey: true;
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		grey: true;
	}
}

declare module "@mui/material/AppBar" {
	interface AppBarPropsColorOverrides {
		grey: true;
	}
}

export const theme = createTheme(defaultTheme, {
	palette: {
		grey: defaultTheme.palette.augmentColor({
			color: {
				main: defaultTheme.palette.grey[600],
			},
			name: "grey",
		}),
	},
});
