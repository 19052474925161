import { useMutation } from "@apollo/client";

import { useState, useCallback, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";

import { Close } from "@mui/icons-material";
import {
	AppBar,
	Box,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";

import { getFragmentData } from "../../__generated__/fragment-masking";
import {
	ProductItemFragment,
	CreateProductInputSchema,
	UpdateProductDocument,
	AddProductDocument,
	GetProductsDocument,
	CreateProductInput,
	ProductStatus,
	AddPictureDocument,
	PictureItemFragmentDoc,
	PictureItemFragment,
	DeletePictureDocument,
	ProductItemFragmentDoc,
	GetProductDocument,
} from "../../__generated__/graphql";
import { QUERIES } from "../../constants";
import { AppButton } from "../../shared";
import { FileDragDrop } from "../../shared/FileDragDrop";
import { capitalize, inputNumberConstraint } from "../../utils";

// TODO: Add in graphics and accordion.
// TODO: Add in remaining fields and add in product preview with thumbnail set and more.
// TODO: Consider adding in category.
export function ProductDetail({
	product,
	close,
}: {
	product?: ProductItemFragment | null;
	close: () => void;
}) {
	const { t } = useTranslation();
	const [files, setFiles] = useState<PictureItemFragment[]>([]);
	const [errorFileNames, setErrorFileNames] = useState<string[]>([]);

	useEffect(() => {
		setFiles(
			product?.pictures.edges.map((edge) => getFragmentData(PictureItemFragmentDoc, edge.node)) ||
				[]
		);
	}, [product]);

	const productFragmentToForm = (fragment: ProductItemFragment): CreateProductInput => ({
		name: fragment.name,
		description: fragment.description,
		price: fragment.price,
		sku: fragment.sku,
		stock: fragment.stock,
		weight: fragment.weight,
		status: fragment.status,
	});

	const productFormToFragment = (form: CreateProductInput): ProductItemFragment => ({
		name: form.name,
		description: form.description,
		price: form.price,
		sku: form.sku,
		stock: form.stock,
		weight: form.weight,
		status: form.status as ProductStatus,
		pictures: { edges: [] },
	});

	const { register, formState, handleSubmit, control } = useForm<CreateProductInput>({
		values: product ? productFragmentToForm(product) : undefined,
		resetOptions: {
			keepDefaultValues: product === undefined ? true : false,
		},
		resolver: yupResolver(CreateProductInputSchema()),
	});

	const [[updateProduct], [addProduct], [addPicture], [deletePicture]] = [
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		useMutation(UpdateProductDocument),
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		useMutation(AddProductDocument),
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		useMutation(AddPictureDocument),
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		useMutation(DeletePictureDocument),
	];

	const onSubmit: SubmitHandler<CreateProductInput> = async (data) => {
		const form = productFormToFragment(data);
		const pictureIds = files.map((file) => file.id);

		if (product?.id) {
			await updateProduct({
				variables: {
					product: {
						...form,
						pictures: pictureIds,
						id: product.id,
					},
				},
				optimisticResponse: {
					updateProduct: {
						...product,
						...form,
						pictures: {
							__typename: "PictureConnection",
							edges: files.map((node) => ({
								__typename: "PictureEdge",
								node,
							})),
						},
						__typename: "Product",
					},
				},
			});
		} else {
			await addProduct({
				variables: {
					product: {
						...form,
						pictures: pictureIds,
					},
				},
				update(cache, { data }) {
					const result = cache.readQuery({
						query: GetProductsDocument,
						variables: QUERIES.products.variables,
					});

					const edges = result && [
						...result.products.edges.map((edge) => ({
							...edge,
							node: getFragmentData(ProductItemFragmentDoc, edge.node),
						})),
						{
							node: getFragmentData(ProductItemFragmentDoc, { ...data?.addProduct }),
							__typename: "ProductEdge" as const,
						},
					];

					if (edges) {
						cache.writeQuery({
							query: GetProductsDocument,
							variables: QUERIES.products.variables,
							data: {
								products: {
									__typename: "ProductConnection",
									edges,
								},
							},
						});
					}
				},
				optimisticResponse: {
					addProduct: {
						id: "temp-id",
						rating: 0,
						...product,
						...form,
						__typename: "Product",
					},
				},
			});
		}
		close();
	};

	const onFilesChange = useCallback(
		async (newFiles: File[]) => {
			let [fragments, errorFileNames]: [PictureItemFragment[], string[]] = [[], []];

			const pictures = await Promise.all(
				newFiles.map((file) =>
					addPicture({ variables: { file } }).catch(() => {
						errorFileNames = [...errorFileNames, file.name];
					})
				)
			);

			for (const picture of pictures) {
				const fragment = getFragmentData(PictureItemFragmentDoc, picture?.data?.addPicture);

				if (fragment) {
					fragments = [...fragments, fragment];
				}
			}

			setErrorFileNames((fileNames) => [...fileNames, ...errorFileNames]);
			setFiles((items) => [...items, ...fragments]);
		},
		[files]
	);

	const onFileRemove = async (id: string) => {
		await deletePicture({ variables: { id } });

		setFiles((items) => [...items.filter((fragment) => fragment.id !== id)]);
	};

	return (
		<Box>
			<AppBar aria-hidden="true" color="primary" position="sticky">
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						sx={{ mr: 2 }}
						onClick={() => close()}
						aria-hidden="true"
						autoFocus={true}
						data-testid="close-product"
					>
						<Close />
					</IconButton>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						{t("Create Product")}
					</Typography>
				</Toolbar>
			</AppBar>
			<Box
				sx={{
					p: 4,
					maxWidth: "700px",
					margin: "0 auto",
				}}
			>
				<form
					onSubmit={handleSubmit(onSubmit)}
					style={{ flexDirection: "column", display: "flex", gap: "12px" }}
				>
					<TextField
						{...register("name")}
						data-testid="name-input"
						error={!!formState.errors.name}
						helperText={capitalize(formState.errors.name?.message)}
						id="name-input"
						InputLabelProps={{ shrink: product?.name ? true : undefined }}
						inputProps={{ maxLength: 256 }}
						label={t("Name")}
						required
					/>
					<TextField
						{...register("description")}
						data-testid="description-input"
						error={!!formState.errors.description}
						helperText={capitalize(formState.errors.description?.message)}
						id="description-input"
						InputLabelProps={{ shrink: product?.description ? true : undefined }}
						inputProps={{ maxLength: 512 }}
						label={t("Description")}
						multiline
						required
						rows={7}
					/>
					<FormControl>
						<InputLabel htmlFor="pricing">{t("Price *")}</InputLabel>
						<OutlinedInput
							{...register("price")}
							data-testid="price-input"
							error={!!formState.errors.price}
							id="pricing-input"
							inputProps={{ step: "1", maxLength: 6 }}
							label="Pricing"
							onKeyDown={(event) => inputNumberConstraint(event, { maxLength: 6, decimal: true })}
							required
							startAdornment={<InputAdornment position="start">€</InputAdornment>}
							type="number"
						/>
						{!!formState.errors.price?.message && (
							<FormHelperText error>{formState.errors.price.message}</FormHelperText>
						)}
					</FormControl>
					<Box sx={{ flexDirection: "row", display: "flex", gap: "12px" }}>
						<TextField
							{...register("sku")}
							data-testid="sku-input"
							error={!!formState.errors.sku}
							helperText={capitalize(formState.errors.sku?.message)}
							id="sku-input"
							InputLabelProps={{ shrink: product?.sku ? true : undefined }}
							inputProps={{ maxLength: 32 }}
							label={t("SKU")}
							required
						/>
						<TextField
							{...register("stock")}
							data-testid="stock-input"
							error={!!formState.errors.stock}
							helperText={capitalize(formState.errors.stock?.message)}
							id="stock-input"
							InputLabelProps={{ shrink: product?.stock ? true : undefined }}
							label={t("Stock")}
							onKeyDown={(event) => inputNumberConstraint(event, { maxLength: 4, decimal: false })}
							required
							type="number"
						/>
						<TextField
							{...register("weight")}
							data-testid="weight-input"
							error={!!formState.errors.weight}
							helperText={capitalize(formState.errors.weight?.message)}
							id="weight-input"
							InputLabelProps={{ shrink: product?.weight ? true : undefined }}
							inputProps={{ step: "0.01", maxLength: 5 }}
							label={t("Weight")}
							onKeyDown={(event) => inputNumberConstraint(event, { maxLength: 5, decimal: true })}
							required
							type="number"
						/>
					</Box>
					<Box sx={{ flexDirection: "row", display: "flex", gap: "12px" }}>
						<FormControl fullWidth data-testid="status-input">
							<InputLabel htmlFor="status">{t("Status")}</InputLabel>
							<Controller
								name="status"
								control={control}
								defaultValue={ProductStatus.Draft}
								render={({ field }) => (
									<Select labelId="status" label="status" id="status-select" {...field}>
										{Object.values(ProductStatus).map((status) => (
											<MenuItem key={status} value={status}>
												{status}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
					</Box>
					<FileDragDrop
						filesChange={onFilesChange}
						erroredNames={errorFileNames}
						uploaded={files}
						removeFile={(id) => id && onFileRemove(id)}
						maxFiles={6}
						accept={{
							"image/jpeg": [],
							"image/png": [],
						}}
					/>
					<AppButton type="submit" variant="contained" color="primary">
						{t("Save")}
					</AppButton>
				</form>
			</Box>
		</Box>
	);
}
