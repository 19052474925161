import { Link } from "react-router-dom";

import { Check, Delete, Star, PhotoCameraBack } from "@mui/icons-material";
import { Box, Chip, IconButton, Rating, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { getFragmentData } from "../../__generated__";
import {
	PictureItemFragment,
	PictureItemFragmentDoc,
	ProductItemFragment,
} from "../../__generated__/graphql";
import { theme } from "../../theme";

const Item = styled(Box)(({ theme }) => ({
	backgroundColor: "transparent",
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
	display: "flex",
	flexDirection: "row",
	gap: "12px",
}));

const AppLink = styled(Link)(({ theme }) => ({
	display: "flex",
	borderRadius: "20px",
	width: "100%",
	textDecoration: "none",
	"&:hover, &:focus": {
		backgroundColor: theme.palette.grey[50],
		borderColor: theme.palette.grey[700],
		outline: "10px auto -webkit-focus-ring-color !important",
	},
}));

export function ProductCard({
	productFragment,
	onDelete,
}: {
	productFragment: ProductItemFragment;
	onDelete: () => void;
}) {
	const firstPicture = productFragment.pictures.edges[0]
		? productFragment.pictures.edges[0].node
		: undefined;

	const pictureFragment = getFragmentData(PictureItemFragmentDoc, firstPicture);

	const thumbnail = pictureFragment?.thumbnail;

	return (
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		<AppLink to={`/products/${productFragment.id}`} color="inherit">
			<Item
				data-testid="product-card"
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					flex: 1,
				}}
			>
				<Box style={{ display: "flex", flexDirection: "row" }}>
					{thumbnail ? (
						<img
							style={{ borderRadius: "20px", width: "140px", height: "140px" }}
							src={thumbnail}
						/>
					) : (
						<Box
							style={{
								borderRadius: "20px",
								width: "140px",
								height: "140px",
								backgroundColor: theme.palette.grey[200],
								alignContent: "center",
								alignItems: "center",
							}}
						>
							<PhotoCameraBack style={{ fontSize: "64px", color: theme.palette.grey[500] }} />
						</Box>
					)}
					<Box
						sx={{
							ml: 2,
							display: "flex",
							justifyContent: "flex-start",
							flexDirection: "column",
							flex: 2,
							alignItems: "start",
							textAlign: "left",
						}}
					>
						<Box
							style={{
								display: "flex",
								gap: "12px",
							}}
						>
							<Typography
								style={{
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									width: "250px",
									overflow: "hidden",
								}}
								variant="h6"
							>
								{productFragment.name}
							</Typography>
						</Box>

						<Box style={{ display: "flex", gap: "12px" }}>
							<span>€{productFragment.price}</span>
						</Box>
						<Rating
							name="text-feedback"
							value={productFragment.rating}
							readOnly
							precision={0.5}
							style={{ marginTop: "5px", marginBottom: "12px" }}
							emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
						/>
						<Box style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
							<Chip color="success" icon={<Check />} label={"Active"} />
							<small style={{ lineHeight: "32px" }}>(stock: {productFragment.stock})</small>
						</Box>
					</Box>
				</Box>
				<Box style={{ display: "flex", flexDirection: "row" }}>
					<Box
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginLeft: "12px",
							gap: "12px",
						}}
					>
						<IconButton
							data-testid="product-card-delete"
							onClick={($event) => {
								onDelete();
								$event.preventDefault();
							}}
							aria-label="delete"
						>
							<Delete />
						</IconButton>
					</Box>
				</Box>
			</Item>
		</AppLink>
	);
}
