import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Add, Search } from "@mui/icons-material";
import { Box, IconButton, InputBase } from "@mui/material";

// import { styled } from "@mui/material/styles";
import { theme } from "../theme";

// enum ListView {
// 	Grid = "grid",
// 	List = "list",
// }

export function AppSearch({
	add,
	search,
	// filters,
	searchChange,
}: // viewChange,
{
	add?: () => void;
	search?: () => void;
	// filters?: () => void;
	searchChange?: (word: string) => void;
	// viewChange?: () => ListView;
}) {
	const { t } = useTranslation();

	const onSearchChange = ($event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		searchChange && searchChange(($event.target as HTMLInputElement).value);
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				gap: "12px",
			}}
		>
			<Box>
				{/* <AppButton onClick={filters} color="grey" variant="outlined" startIcon={<Tune />}>
					{t("Filters")}
				</AppButton> */}
			</Box>
			<Box
				sx={{
					width: "100%",
					maxWidth: "700px",
					flexDirection: "row",
					display: "flex",
					gap: "12px",
				}}
			>
				<Box
					component="form"
					sx={{
						p: "2px 4px",
						display: "flex",
						alignItems: "center",
						borderRadius: "20px",
						backgroundColor: theme.palette.grey[100],
						flex: "1",
					}}
				>
					<InputBase
						sx={{ ml: 1, flex: 1 }}
						placeholder={t("Search products")}
						inputProps={{ "aria-label": t("Search products") }}
						onChange={onSearchChange}
						data-testid="search-term-input"
					/>
					<IconButton
						data-testid="search-button"
						onClick={search}
						type="button"
						sx={{ p: "10px" }}
						aria-label="search"
					>
						<Search />
					</IconButton>
				</Box>
				<IconButton data-testid="search-add-button" onClick={add} aria-label="add" size="large">
					<Add fontSize="inherit" />
				</IconButton>
			</Box>
			<Box>
				{/* <ButtonGroup color="grey" variant="outlined" aria-label="Disabled button group">
					<AppButton onClick={viewChange}>
						<GridView />
					</AppButton>
					<AppButton onClick={viewChange}>
						<ViewList />
					</AppButton>
				</ButtonGroup> */}
			</Box>
		</Box>
	);
}
