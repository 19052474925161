import { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Container } from "@mui/material";

import { QUERIES } from "../../constants";
import { AppError, AppSearch } from "../../shared";

import { ProductModal } from "./ProductModal";
import ProductsList from "./ProductsList";

export function Products() {
	const navigate = useNavigate();
	const { id } = useParams();

	const [open, setOpen] = useState(false);

	useEffect(() => {
		id && setOpen(true);
	}, [id]);

	const closeModal = () => {
		setOpen(false);
		navigate("/products");
	};

	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				height: "100vh",
				overflow: "auto",
				paddingLeft: "103px",
			}}
		>
			<Container maxWidth={false} sx={{ mb: 4 }}>
				<Box
					sx={{
						p: 2,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<AppSearch add={() => navigate("/products/new")} />

					<ErrorBoundary fallback={<AppError />}>
						<Suspense fallback={<></>}>
							<ProductsList options={{ variables: QUERIES.products.variables }} />
						</Suspense>
					</ErrorBoundary>

					{open && (
						<ProductModal
							open={open}
							close={closeModal}
							options={{ variables: { id: id ? id : "new" }, skip: id === "new" ? true : false }}
						/>
					)}
				</Box>
			</Container>
		</Box>
	);
}
