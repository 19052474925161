import { QueryHookOptions, useQuery } from "@apollo/client";

import { MouseEvent } from "react";

import { Backdrop, Paper, Slide, Modal, Box } from "@mui/material";

import { getFragmentData } from "../../__generated__";
import {
	Exact,
	GetProductDocument,
	GetProductQuery,
	ProductItemFragmentDoc,
} from "../../__generated__/graphql";

import { ProductDetail } from "./ProductDetail";

export function ProductModal({
	open,
	close,
	options,
}: {
	open: boolean;
	close: () => void;
	options: QueryHookOptions<GetProductQuery, Exact<{ id: string }>>;
}) {
	const { data } = useQuery(GetProductDocument, options);

	const fragment = getFragmentData(ProductItemFragmentDoc, data?.product);

	const backdropClose = () => {
		close();
	};

	return (
		<Modal
			aria-modal={true}
			open={open}
			onClose={backdropClose}
			style={{ display: "flex", justifyContent: "end", height: "100%" }}
		>
			<Slide direction="left" in={open}>
				<Paper
					sx={{
						height: "100%",
						width: ["100%", "80%", "55%"],
						position: "relative",
						overflow: "auto",
					}}
					elevation={5}
					aria-modal="true"
				>
					<ProductDetail close={close} product={fragment} />
				</Paper>
			</Slide>
		</Modal>
	);
}
