import { KeyboardEvent } from "react";

// TODO: Weight 0 - Library for validation on keydown - number validator
const maxLength = (value: string, limit: number): boolean => {
	if (value.length > limit) {
		return true;
	}

	return false;
};

const isNumberOnly = (eventKey: string, decimal?: boolean): boolean => {
	const numberRegex = decimal ? /^\d*\.?\d*$/ : /^\d+$/;

	if (numberRegex.test(eventKey) || eventKey === "Backspace" || eventKey === "Tab") {
		return true;
	}

	return false;
};

export const inputNumberConstraint = (
	event: KeyboardEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>,
	options: { maxLength: number; decimal?: boolean }
): void => {
	const target = event.target as HTMLInputElement;

	if (!isNumberOnly(event.key, options.decimal)) {
		event.preventDefault();
	}

	if (
		typeof target.value === "string" &&
		maxLength(target.value, options.maxLength) &&
		event.key !== "Backspace"
	) {
		event.preventDefault();
	}
};
