import { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Dashboard, People, ShoppingBasket, ShoppingCart } from "@mui/icons-material";
import {
	Drawer,
	List,
	ListItemButton,
	ListItemButtonBaseProps,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { theme } from "../../theme";

const secondaryTypographyProps = { fontSize: "12px" };

const AppListItemIcon = styled(ListItemIcon)({
	minWidth: "initial",
	flexDirection: "column",
	alignItems: "center",
	transform: "scale(1)",
	svg: {
		transition: "all 0.1s ease-out",
	},
	"&:hover svg": {
		transform: "scale(1.1)",
		color: `${theme.palette.grey[900]}`,
	},
});

const AppListItemButton = styled(ListItemButton)<
	ListItemButtonBaseProps & { component: ElementType; to: string }
>({
	justifyContent: "center",
	margin: "12px 5px",
	borderRadius: "100px",
});

const AppListItemText = styled(ListItemText)({
	marginBottom: "0px",
});

// TODO: Adapt drawer to current view such as products. https://dribbble.com/shots/19344384-Dynamic-Navigation-Bar
export function AppDrawer() {
	const { t } = useTranslation();

	return (
		<Drawer variant="permanent">
			<List component="nav">
				<AppListItemButton component={Link} to="/">
					<AppListItemIcon>
						<Dashboard />
						<AppListItemText
							secondaryTypographyProps={secondaryTypographyProps}
							secondary={t("Dashboard")}
						/>
					</AppListItemIcon>
				</AppListItemButton>
				<AppListItemButton component={Link} to="/orders">
					<AppListItemIcon>
						<ShoppingCart />
						<AppListItemText
							secondaryTypographyProps={secondaryTypographyProps}
							secondary={t("Orders")}
						/>
					</AppListItemIcon>
				</AppListItemButton>
				<AppListItemButton component={Link} to="/products">
					<AppListItemIcon>
						<ShoppingBasket />
						<AppListItemText
							secondaryTypographyProps={secondaryTypographyProps}
							secondary={t("Products")}
						/>
					</AppListItemIcon>
				</AppListItemButton>
				<AppListItemButton component={Link} to="/customers">
					<AppListItemIcon>
						<People />
						<AppListItemText
							secondaryTypographyProps={secondaryTypographyProps}
							secondary={t("Customers")}
						/>
					</AppListItemIcon>
				</AppListItemButton>
			</List>
		</Drawer>
	);
}
