import { useTranslation } from "react-i18next";

import Lottie from "lottie-react";

import { Home } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import errorAnimation from "../assets/error-animation.json";

import { AppButton } from "./AppButton";

export function AppError() {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginTop: "32px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: ["column", "column", "row"],
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box>
					<Typography variant="h2">{t("Something went wrong...")}</Typography>
					<p>{t("Please try again later")}</p>
					<AppButton color="grey" variant="outlined" startIcon={<Home />}>
						{t("Go home")}
					</AppButton>
				</Box>
				<Lottie animationData={errorAnimation} loop={true} />
			</Box>
		</Box>
	);
}
